<template>
  <div class="auth-wrapper auth-v2 px-2">
    <showAt breakpoint="small">
      <div class="btn_prev_container">
          <div class="btn_prev_stage_mobile" style="margin-top: 20px;"> 
              <b-link :to="{name:'auth-login'}">
                  <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
              </b-link>
          </div>
      </div>
    </showAt>
    <div class="auth-inner py-2">
      <!-- Reset Password v2 -->
      <b-card class="mb-0">

        <!-- logo -->
        <center>
          <showAt breakpoint="mediumAndAbove">
            <div class="btn_prev_stage_desktop" style="margin-top: 20px;"> 
              <b-link :to="{name:'auth-login'}">
                <feather-icon icon="ChevronUpIcon" size="24" style="color: #9CA2B1; transform: rotate(-90deg);" />
              </b-link>
            </div>
          </showAt>
          <b-img v-if="skin.value === 'light' " src="~@/assets/images/logo/logo_title_flat.png" fluid width="110px" />
          <b-img v-else src="~@/assets/images/logo/logo_title_flat_white.png" fluid width="110px" />
        </center>
          
        <b-card-title class="text-center" style="margin-top: 32px; margin-bottom: 4px; font-size: 20px">
          {{$t('reset.title')}}
        </b-card-title>
       
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Пароль"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    :placeholder="$t('reset.placeholders.password')"
                    @focus="focusPassword = true"
                    @blur="focusPassword = false"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <transition name="fade">
                    <div v-if="focusPassword" class="d-flex validation-card mt-1" style="flex-direction:column">
                        <span class="font-weight-bolder">{{$t('register.validate.msg')}}</span>
                        <span :class="validationPassword.minLength ? 'text-success' : 'text-danger'">  
                            <feather-icon v-if="validationPassword.minLength" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.symbols')}}
                        </span>
                        <span  :class="validationPassword.register ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationPassword.register" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.upper-lower-case')}}
                        </span>
                        <span  :class="validationPassword.numbers ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationPassword.numbers" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.numbers')}}
                        </span>
                        <span  :class="validationPassword.specialСharacter ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationPassword.specialСharacter" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.special-sharacter')}} <br>
                            ! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \ ] ^ _` { | } ~ 
                        </span>
                    </div>
                </transition>

              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Подтверждение пароля"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="password_confirm"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    :placeholder="$t('reset.placeholders.confirm-password')"
                    @focus="focusConfirmPassword = true"
                    @blur="focusConfirmPassword = false"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <transition name="fade">

               <div v-if="focusConfirmPassword" class="d-flex validation-card mt-1" style="flex-direction:column">
                        <span class="font-weight-bolder">{{$t('register.validate.msg')}}</span>
                        <span :class="validationConfirmPassword.minLength ? 'text-success' : 'text-danger'">  
                            <feather-icon v-if="validationConfirmPassword.minLength" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.symbols')}}
                        </span>
                        <span  :class="validationConfirmPassword.register ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationConfirmPassword.register" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.upper-lower-case')}} 
                        </span>
                        <span  :class="validationConfirmPassword.numbers ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationConfirmPassword.numbers" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.numbers')}} 
                        </span>
                        <span  :class="validationConfirmPassword.specialСharacter ? 'text-success' : 'text-danger'"> 
                            <feather-icon v-if="validationConfirmPassword.specialСharacter" icon="CheckIcon" size="14" />
                            <feather-icon v-else icon="XIcon" size="14" />
                            {{$t('register.validate.special-sharacter')}} <br>
                            ! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \ ] ^ _` { | } ~ 
                        </span>
                    </div>
                </transition>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              class="submit-auth-v2"
            >
              {{$t('reset.btns.reset')}}
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useAppConfig from '@core/app-config/useAppConfig'


export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      
      password: '',
      password_confirm: '',
      
      skin: useAppConfig().skin,
      
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      validationPassword: {
          minLength: false,
          register: false,
          numbers: false,
          specialСharacter: false
      },
      validationConfirmPassword: {
          minLength: false,
          register: false,
          numbers: false,
          specialСharacter: false
      },

      focusConfirmPassword: false,
      focusPassword: false,
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          
           this.$request.post("user.passwordReset", {
               new_password: this.password,
               uix: this.$route.params.link
           }).then( result => {
               
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('toasts.titles.reset'),
                      title: this.$t('toasts.text.reset-password'),
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                });
                
                this.$router.replace({ name: "auth-login" });
               
           }).catch( err => {
              
               this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('toasts.titles.error'),
                      text: err.message,
                      icon: 'EditIcon',
                      variant: 'danger',
                    },
                });
               
           });
          
        }
      })
    },
    checkSpecialСharacter(str) {
        let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return format.test(str);
    },

    checkCasesStr(str) {

        let upper = false;
        let lower = false;

        let i=0;
        let character='';
        while (i <= str.length) {
            character = str.charAt(i);
            if (!isNaN(character * 1)){
            }else if (!this.checkSpecialСharacter(character)){
                
                if (character == character.toUpperCase()) {
                   upper = true
                }
                if (character == character.toLowerCase()){
                   lower = true
                }
            }
            i++;
        }
        if (str.search(/[А-яЁё]/) !== -1) return false

        if (upper && lower) return true;
        else return false;
    },
  },
  watch: {
     password: {  
      deep: true,
      handler(bef) {
        this.validationPassword.specialСharacter = this.checkSpecialСharacter(bef);
        this.validationPassword.minLength = bef.length >= 10 ? true : false;
        this.validationPassword.register = this.checkCasesStr(bef)
        this.validationPassword.numbers = /[0-9]/.test(bef);
      }
    },
    password_confirm: {
      deep: true,
      handler(bef) {
        this.validationConfirmPassword.specialСharacter = this.checkSpecialСharacter(bef);
        this.validationConfirmPassword.minLength = bef.length >= 10 ? true : false;
        this.validationConfirmPassword.register = this.checkCasesStr(bef)
        this.validationConfirmPassword.numbers = /[0-9]/.test(bef);
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
